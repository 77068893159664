import {lazy} from "react";
const Contact = lazy(() => import("./views/contact/Contact"));
const Breadcrumbs = lazy(() => import("./widgets/Breadcrumbs"));
const PublicLayout = lazy(() => import("./views/layout/PublicLayout"));
const Error404 = lazy(() => import("./views/error404/Error404"));
const PopupImage = lazy(() => import("./widgets/PopupImage"));


const exports = {
    routes: [
        {name: "PublicLayout", path: "/", element: <PublicLayout />},
        {
            name: "contact",
            path: ["kontakt", "contact"],
            layouts: ["public.PublicLayout"],
            element: <Contact />,
        },
        {name: "error404", path: ["*"], element: <Error404 />, layouts: ["public.PublicLayout"]},
    ],
    widgets: {
        Error404: Error404,
        Breadcrumbs: Breadcrumbs,
        PopupImage: PopupImage
    },
}
export default exports;
