import {lazy} from "react";
import {languages} from "./config";
import LangMatchWrapper from "./wrappers/LangMatchWrapper";
import LangHooksWrapper from "./wrappers/LangHooksWrapper";
const Wrapper = lazy(() => import("./widgets/Wrapper"));
const LangSelect = lazy(() => import("./widgets/LangSelect"));

const exports = {
    routes: [

    ],
    wrappers: [LangMatchWrapper, LangHooksWrapper],
    widgets: {
        LangSelect: LangSelect,
        Wrapper: Wrapper,
        languages: languages
    }
}
export default exports;