import {gql} from "@apollo/client";
import {STORAGE_FRAGMENT} from "../../storage/graphql/storage";


export function FILTER_FRAGMENT()   {
    return gql`
        ${STORAGE_FRAGMENT}
        fragment FilterFragment on Filter {
            id
            name
            names
            parent_id
            position
            shop
            display
            locked
            image {
                ...StorageFragment
            }
        }
    `;
}


export const GET_FILTERS_QUERY = "getFilters";
export function GET_FILTERS(params)     {
    return gql`
        ${FILTER_FRAGMENT()}
        query ($ids: [ID!], $parent_id: Int, $category: [ID], $shop: Boolean,
            $brandName: String, $pageName: String, $group: ID) {
            getFilters(ids: $ids, parent_id: $parent_id, category: $category, shop: $shop, 
                pageName: $pageName, brandName: $brandName, group: $group) {
                ...FilterFragment
                ${params?.shop
                    ? `products_count
                    parent {
                        ...FilterFragment
                    }`
                    : `children {
                        ...FilterFragment
                        products_count
                }`}
            }
        }
    `
}

export function UPSERT_FILTER()     {
    return gql`
        ${FILTER_FRAGMENT()}
        mutation ($id: ID, $name: String, $names: [String], $parent_id: ID, $image_id: ID, $display: Boolean, $shop: Boolean)  {
            upsertFilter(id: $id, name: $name, names: $names, parent_id: $parent_id, image_id: $image_id, display: $display, shop: $shop) {
                ...FilterFragment
            }
        }
    `
}
export function DELETE_FILTER()  {
    return gql`
        ${FILTER_FRAGMENT()}
        mutation ($id: ID!) {
            deleteFilter(id: $id) {
                ...FilterFragment
            }
        }
    `
}
