import {lazy} from "react";
const BlocksRender = lazy(() => import(/* webpackChunkName: "BlocksRender" */ "./widgets/BlocksRender"));
const Blocks = lazy(() => import("./views/cmsDesign/Blocks"));
const IndexSlider = lazy(() => import("./widgets/renders/IndexSlider"));
const BannersCarousel = lazy(() => import("./widgets/renders/BannersCarousel"));

const exports = {
    routes: [

    ],
    widgets: {
        Blocks: Blocks,
        BlocksRender: BlocksRender,
        BannersCarousel: BannersCarousel,
        IndexSlider: IndexSlider,
    }
}
export default exports;