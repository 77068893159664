import {lazy} from "react";
import WebsocketWrapper from "./wrappers/WebsocketWrapper";
import InitWebsocket from "./widgets/InitWebsocket";
const CheckStateButton = lazy(() => import("./widgets/CheckStateButton"));
const ChatPopup = lazy(() => import("./widgets/chatPopup/ChatPopup"));
const CountLive = lazy(() => import("./views/live/CountLive"));
const Live = lazy(() => import("./views/live/Live"));
const CmsChat = lazy(() => import("./views/cmsChat/CmsChat"));



const exports = {
    routes: [
        {
            name: "cmsLiveTab",
            trans: ["Live", "Live"],
            path: ["uzivo", "live"],
            element: <Live />,
            append: <CountLive />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 40}},
        },
        {
            name: "cmsChat",
            trans: ["Chat", "Chat"],
            path: ["chat", "chat-en"],
            parent: "live.cmsLiveTab",
            element: <CmsChat />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 2}},
        },
    ],
    wrappers: [WebsocketWrapper],
    widgets: {
        ChatPopup: ChatPopup,
        CheckStateButton: CheckStateButton,
        InitWebsocket: InitWebsocket
    },
}
export default exports;
