import {gql} from "@apollo/client";
import {RESPONSE_FRAGMENT} from "../../../elements/graphql/response";
import {PAGINATOR_INFO_FRAGMENT} from "../../../elements/graphql/paginatorInfo";


export const STORAGE_FRAGMENT = gql`
    fragment StorageFragment on Storage {
        id
        name
        orig_name
        mime
        width
        height
        alt
        tab
        bucket
        folder
    }
`;


export const GET_STORAGE_QUERY = "getStorage";
export const GET_STORAGE = gql`
    ${STORAGE_FRAGMENT}
    ${PAGINATOR_INFO_FRAGMENT}
    query ($first: Int, $page: Int, $search: String, $tab: String) {
        getStorage(first: $first, page: $page, search: $search, tab: $tab) {
            data {
                ...StorageFragment
            }
            paginatorInfo {
                ...PaginatorInfoFragment
            }
        }
    }
`
export const DELETE_STORAGE = gql`
    ${STORAGE_FRAGMENT}
    mutation ($ids: [ID!]!) {
        deleteStorage(ids: $ids) {
           ...StorageFragment
        }
    }
`
export const UPDATE_STORAGE = gql`
    ${STORAGE_FRAGMENT}
    mutation ($id: ID!, $tab: String) {
        updateStorage(id: $id, tab: $tab) {
            ...StorageFragment
        }
    }
`

export const UPLOAD_STORAGE_QUERY = "uploadStorage";
export const UPLOAD_STORAGE = gql`
    ${RESPONSE_FRAGMENT}
    mutation ($file: Upload!, $bucket: String!) {
        uploadStorage(file: $file, bucket: $bucket) {
            ...ResponseFragment
        }
    }
`