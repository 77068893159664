import {gql} from "@apollo/client";
import {graphql} from "../../modules";
import {loadImage} from "../../_settings/config";


export function BRAND_FRAGMENT() {
    return gql`
        ${loadImage ? graphql("storage.storage").fragment : ""}
        fragment BrandFragment on Brand {
            id
            name
            slug
            position
            description
            descriptions
            ${loadImage ? graphql("storage.storage", "image").body : ""}
        }
    `;
}

export const GET_BRANDS_QUERY = "getBrands";
export function GET_BRANDS(params) {
    return gql` 
        ${BRAND_FRAGMENT()}
        query($search: String, $name: String, $pageName: String, $category: [ID], $group: ID) {
            getBrands(search: $search, name: $name, pageName: $pageName, category: $category, group: $group)  {
                ...BrandFragment
                ${params?.shop
                    ? "shop_products_count"
                    : "products_count"}
            }
        }
    `
}

export function UPSERT_BRAND() {
    return gql`
        ${BRAND_FRAGMENT()}
        mutation ($id: ID, $name: String, $slug: String, $image_id: ID, $description: String)  {
            upsertBrand(id: $id, name: $name, slug: $slug, image_id: $image_id, description: $description) {
                ...BrandFragment
            }
        }
    `
}

export function DELETE_BRAND() {
    return gql`
        ${BRAND_FRAGMENT()}
        mutation ($id: ID!) {
            deleteBrand(id: $id) {
                ...BrandFragment
            }
        }
    `
}
