import {modulesRoutes} from "../../modules";
import {matchRoutes} from "react-router-dom";
import {from, useApolloClient} from "@apollo/client";
import {authLink, uploadLink} from "../../_app/apolloClient";


function LangMatchWrapper({Next, context}) {
    let lang = 0;
    const client = useApolloClient();
    if(context.languages.length === 1)  {
        return <Next context={context} />
    }
    const routes = Object.values(modulesRoutes);
    const holder = [];
    for(let i=0; i<routes.length; i++)    {
        for(let j=0; j<routes[i].fullpath.length; j++)    {
            holder.push({path: routes[i].fullpath[j], lang: j});
        }
    }
    const test = matchRoutes(holder, window.location.pathname);
    if(test && test.length > 0) {
        lang = test[0]?.route?.lang || 0;
        if(lang !== 0)  {                      // Apdate apollo client with lang
            client.setLink(from([authLink({...context, lang: lang}), uploadLink()]));
        }
    }
    return <Next context={{...context, lang: lang}} />
}
export default LangMatchWrapper;