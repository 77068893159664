import {gql} from "@apollo/client";
import {PAGINATOR_INFO_FRAGMENT} from "../../../elements/graphql/paginatorInfo";


export function SUPPLIER_FRAGMENT() {
    return gql`
        fragment SupplierFragment on Supplier {
            id
            active
            name
            classname
            description
            website
            format
            updating
            created_at
        }
    `
}

export const GET_SUPPLIER_QUERY = "getSupplier";
export function GET_SUPPLIER()    {
    return gql`
        ${SUPPLIER_FRAGMENT()}
        query ($id: ID!) {
            getSupplier(id: $id) {
                ...SupplierFragment
            }
        }
    `
}

export const GET_SUPPLIERS_QUERY = "getSuppliers";
export function GET_SUPPLIERS(params)    {
    return gql`
        ${SUPPLIER_FRAGMENT(params)}
        ${PAGINATOR_INFO_FRAGMENT}
        query ($page: Int, $search: String, $order: Int, $start_date: String, $end_date: String, $price_diff: Boolean) {
            getSuppliers(page: $page, search: $search, order: $order, start_date: $start_date,
                end_date: $end_date, price_diff: $price_diff) {
                data {
                    ...SupplierFragment
                    products_count
                    products_views
                    products_state_count
                    products_nostate_count
                    products_active_count
                    products_deactive_count

                    price_diff
                    order_products_count
                    order_products_total
                    order_products_profit
                }
                paginatorInfo {
                    ...PaginatorInfoFragment
                }
            }
        }
    `
}
export function UPDATE_SUPPLIER() {
    return gql`
        ${SUPPLIER_FRAGMENT()}
        mutation ($id: ID!, $activateAll: Boolean)  {
            updateSupplier(id: $id, activateAll: $activateAll) {
                ...SupplierFragment
            }
        }
    `
}
