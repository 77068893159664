
export const productGraphql = {
    price: false,
    qty: false,
    variants: true,
    specifications: true,
    connected: true,
    youtube: true,
    delete: false
}
export const shopUrls = ["prodavnica", "shop"]
export const productUrls = ["proizvod", "product"]
export const groupUrls = ["grupa", "group"]
export const brandUrls = ["brend", "brand"]