import {Suspense} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import {modulesMiddlewares, modulesRoutes} from "../modules";
import AppContext from "./AppContext";


function Router({context}) {

    const middlewareWrappers = (content, index=0) => {   // Wrappers after router (SEO wrapper)
        if(!modulesMiddlewares[index])       {
            return content
        }
        const Wrapper = modulesMiddlewares[index];
        return <Wrapper>
            {middlewareWrappers(content, index+1)}
        </Wrapper>
    }

    const layoutWrappers = (route, lang, index=0) => {
        if(!route.layouts[index])       {
            return <Route key={index} index path={route.fullpath[lang]} element={
                <Suspense fallback={<div />}>
                    {route.element}
                </Suspense>
            } />
        }
        if(index === 0) {
            return <Route key={index} path="" element={
                <AppContext.Provider value={{currentRoute: route, ...context}}>
                    {middlewareWrappers(route.layouts[index].element)}
                </AppContext.Provider>
            }>
                {layoutWrappers(route, lang, index+1)}
            </Route>
        }
        return <Route key={index} path="" element={
            <Suspense fallback={<div />}>
                {route.layouts[index].element}
            </Suspense>
        }>
            {layoutWrappers(route, lang, index+1)}
        </Route>
    }

    return <BrowserRouter>
        <ScrollToTop />
        <Suspense fallback={<div />}>
            <Routes>
                {Object.values(modulesRoutes).map((route, index) => {
                    if(route.page)  {
                        if(!route.page.active)      return null;
                        if(route.page.category_id)  return null;
                        if(route.page.brand_name)   return null;
                        if(route.page.group_id)     return null;
                    }
                    return route.path.map((path, lang) => {
                        if(context.languages && !context.languages[lang])   {
                            return null;
                        }
                        return layoutWrappers(route, lang);
                    })
                })}
            </Routes>
        </Suspense>
    </BrowserRouter>
}
export default Router;
