import {seoAll} from "./providers/seoProvider";
import RouteSeoMiddleware from "./middlewares/RouteSeoMiddleware";


const exports = {
    routes: [

    ],
    middlewares: [RouteSeoMiddleware],
    widgets: {
        seoAll: seoAll
    }
}
export default exports;