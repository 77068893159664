import {useQuery} from "@apollo/client";
import {GET_PAGES, GET_PAGES_QUERY} from "../graphql/pages";
import PageTemplate from "../views/templates/PageTemplate";
import {addToRoutes, modulesRoutes} from "../../modules";


function LoadPagesWrapper({Next, context}) {
    const {data, loading} = useQuery(GET_PAGES, {
        skip: window.goPages,
    });
    if(loading) {
        return null;
    }
    let pages;
    if(window.goPages) {
        pages = window.goPages;
    } else {
        pages = (data && data[GET_PAGES_QUERY]) ? data[GET_PAGES_QUERY] : [];
    }
    const holder = {};

    for(let i=0; i<pages.length; i++)   {
        const page = pages[i];
        if(page.page)   {
            if(modulesRoutes[page.page]) modulesRoutes[page.page].page = page;
            if(page.page.startsWith("products."))   {
                for(let i=1; i<6; i++)  {
                    if(modulesRoutes[page.page+i]) modulesRoutes[page.page+i].page = page;
                }
            }
            continue;
        }
        holder[`page.${page.id}`] = {
            path: page.slugs,
            element: <PageTemplate />,
            layouts: ["public.PublicLayout"],
            page: page,
            name: `page.${page.id}`}
    }
    addToRoutes(holder);

    return <Next context={{...context, pages: pages}} />
}
export default LoadPagesWrapper;