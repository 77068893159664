import {lazy} from "react";
import {campaignVar} from "./config";
import CampaignWrapper from "./wrappers/CampaignWrapper";
const CmsGroups = lazy(() => import("./views/cmsGroups/CmsGroups"));
const CmsGroupsDropDown = lazy(() => import("./widgets/CmsGroupsDropDown"));
const CmsDeleteProductButton = lazy(() => import("./widgets/CmsDeleteProductButton"));

const exports = {
    routes: [
        {
            name: "cmsGroups",
            trans: ["Grupe proizvoda", "Products groups"],
            path: ["grupe-proizvoda", "products-groups"],
            element: <CmsGroups />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 20}},
        },

    ],
    wrappers: [CampaignWrapper],
    widgets: {
        campaignVar: campaignVar,
        CmsGroupsDropDown: CmsGroupsDropDown,
        CmsDeleteProductButton: CmsDeleteProductButton
    },
}
export default exports;
