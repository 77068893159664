import React, {useContext} from "react";
import Ripples from "react-ripples";
import {Link} from "react-router-dom";
import {Heart} from "react-feather";
import AppContext from "../../_app/AppContext";


function WishlistButton() {
    const {route, trans, wishlist} = useContext(AppContext);

    return (
        <Ripples>
            <Link to={route("wishlist.wishlist")} className="w-full inline-block text-center relative">
                <div className="p-3">
                    <div className="relative inline-block">
                            <span style={{top: -8, right: -12}} className="absolute text-sm pointer-events-none z-10 bg-pubprimary-1 rounded-full font-bold text-white w-5 h-5 text-center">
                                <div className="relative z-10 text-md"> {wishlist.length} </div>
                            </span>
                        <Heart />
                    </div>
                    {<div className="text-sm md:hidden">{trans("omiljeno")}</div>}
                </div>
            </Link>
        </Ripples>
    )
}

export default WishlistButton;