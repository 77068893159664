import {gql} from "@apollo/client";
import {loadImage1, loadImage2} from "../config";
import {graphql} from "../../modules";
import {PAGINATOR_INFO_FRAGMENT} from "../../../elements/graphql/paginatorInfo";


export function CATEGORY_FRAGMENT()  {
    return gql`
        ${loadImage1 ? graphql("storage.storage").fragment : ""}
        fragment CategoryFragment on Category {
            id
            name
            parent_id
            slug
            description
            names
            slugs
            descriptions
            seo_title
            seo_keywords
            seo_description
            seo_titles
            seo_keywords_all
            seo_descriptions
            ${loadImage1 ? graphql("storage.storage", "image").body : ""}
            ${loadImage2 ? graphql("storage.storage", "image_2").body : ""}
        }
    `;
}
export function SHOP_CATEGORY_FRAGMENT()  {
    return gql`
        fragment ShopCategoryFragment on Category {
            id
            name
            parent_id
            slug
        }
    `;
}

export const GET_CATEGORIES_QUERY = "getCategories";
export function GET_CATEGORIES(params)    {
    return gql`
        ${params?.shop ? SHOP_CATEGORY_FRAGMENT() : CATEGORY_FRAGMENT()}
        query ($search: String, $pageName: String, $brandName: String, $group: ID) {
            getCategories(search: $search, pageName: $pageName, brandName: $brandName, group: $group) {
                ${params?.shop
                    ? `
                        ...ShopCategoryFragment
                        shop_products_count
                    `
                    : `
                        ...CategoryFragment
                        products_count
                    `}
            }
        }
    `
}

export function UPSERT_CATEGORY() {
    return gql`
        ${CATEGORY_FRAGMENT()}
        mutation ($id: ID, $name: String, $slug: String, $description: String, $parent_id: ID, 
            $image_id: ID, $image_id_2: ID,
            $seo_title: String, $seo_description: String, $seo_keywords: String)  {
            upsertCategory(id: $id, name: $name, slug: $slug, description: $description, parent_id: $parent_id,
                image_id: $image_id, image_id_2: $image_id_2,
                seo_title: $seo_title, seo_description: $seo_description, seo_keywords: $seo_keywords) {
                ...CategoryFragment
            }
        }
    `
}

export function DELETE_CATEGORY() {
    return gql`
        ${CATEGORY_FRAGMENT()}
        mutation ($id: ID!) {
            deleteCategory(id: $id) {
                ...CategoryFragment
            }
        }
    `
}






export const GET_CATEGORIES_WITH_PAGINATION_QUERY = "getCategoriesWithPagination";
export function GET_CATEGORIES_WITH_PAGINATION()    {
    return gql`
        ${CATEGORY_FRAGMENT()}
        ${PAGINATOR_INFO_FRAGMENT}
        query ($page: Int, $search: String, $order: Int, $start_date: String, $end_date: String, $price_diff: Boolean) {
            getCategoriesWithPagination(page: $page, search: $search, order: $order,
                start_date: $start_date, end_date: $end_date, price_diff: $price_diff) {
                data {
                    ...CategoryFragment
                    products_count
                    products_views
                    order_products_count
                    order_products_total
                    order_products_profit
                    price_diff
              
                }
                paginatorInfo {
                    ...PaginatorInfoFragment
                }
            }
        }
    `
}



