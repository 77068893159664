import {server} from "../../_app/config";
import {storageUrl} from "../config";

function storageFile(file) {
    if(file && file.name.endsWith(".pdf")) {
        return server("storage/000-pdf.png");
    }
    return server("storage/000-file.png");
}
function noImage() {
    return server("storage/000-no-image.png");
}
export function image(image, lg=false) {
    if(!image)  {
        return noImage();
    }
    if (typeof image === "string") {
        return `${storageUrl}/${image}`;
    }

    if(!image.width)    {
        return storageFile(image);
    }
    let imageName = image.name;
    if(lg)  {
        const imageArr = imageName.split(".");
        const mime = imageArr.pop();
        imageName = imageArr.join(".") + "-lg." + mime;
    }

    return `${storageUrl}/${image.bucket}/${image.folder}/${imageName}`;

    //const name = lg ? `lg-${image.name}` : image.name;
    //return server(`storage/slike/${image.folder}/${name}`);
}
