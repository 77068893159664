

const loadCampaigns = () =>  {
    let campaigns = localStorage.getItem("campaigns");
    if(!campaigns) return [];
    try {
        campaigns = JSON.parse(campaigns);
    } catch (e) {
        return [];
    }
    return campaigns;
}

function CampaignWrapper({Next, context})   {

    const checkCampaign = (productId) => {
        const item = loadCampaigns().find(it => it.id === productId);
        return item ? item.cm : null;
    }

    const addCampaign = (productId, campaign) => {
        let campaigns = loadCampaigns();
        let find = campaigns.find(item => item.id === productId);
        if(find)   {
            campaigns = campaigns.map(item => {
                if(item.id !== productId) return item;
                return {id: productId, cm: campaign}
            });
        } else {
            campaigns.unshift({id: productId, cm: campaign});
        }
        campaigns = campaigns.slice(0, 100);
        localStorage.setItem("campaigns", JSON.stringify(campaigns));
    }

    return <Next context={{...context, addCampaign, checkCampaign}} />
}
export default CampaignWrapper;