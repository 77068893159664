import {useContext, useEffect} from "react";
import WebsocketContext from "../contexts/WebsocketContext";


function InitWebsocket({user, name}) {
    const {init} = useContext(WebsocketContext);

    useEffect(() => {
        init(user, name);
    }, []);

    return null;
}
export default InitWebsocket;