import {gql} from "@apollo/client";
import {SUPPLIER_FRAGMENT} from "./suppliers";



export const SUPPLIER_PRODUCT_FRAGMENT = gql`
    ${SUPPLIER_FRAGMENT()}
    fragment SupplierProductFragment on SupplierProduct { 
        id
        active
        name
        state
        stock
        code
        supplier_id
        product_id
        purchase_price
        pdv
        margo
        calculated_price
        admin_price
        admin_discount_price
        admin_price_start
        admin_price_end
        process_price        
        supplier {
            ...SupplierFragment
        }
    }
`;
export const PRODUCT_EPONUDA_LINK_FRAGMENT = gql`
    fragment ProductEponudaLinkFragment on ProductEponudaLink {
        id
        price_diff
        min_price
        max_price
        go_price
        crawled
        e_link
        e_name
    }
`;


export function UPDATE_SUPPLIER_PRODUCT() {
    return gql`
        ${SUPPLIER_PRODUCT_FRAGMENT}
        mutation ($id: ID!, $active: Boolean,$admin_price: Float, $admin_discount_price: Float, 
                    $admin_price_start: String, $admin_price_end: String)  {
            updateSupplierProduct(id: $id, active: $active,admin_price: $admin_price, admin_discount_price: $admin_discount_price,
                admin_price_start: $admin_price_start, admin_price_end: $admin_price_end,) {
                ...SupplierProductFragment
            }
        }
    `
}
export function RESET_EPONUDA_LINK() {
    return gql`
        ${PRODUCT_EPONUDA_LINK_FRAGMENT}
        mutation ($id: ID, $e_link: String, $product_id: ID)  {
            resetEponudaLink(id: $id, e_link: $e_link, product_id: $product_id) {
                ...ProductEponudaLinkFragment
            }
        }
    `
}
