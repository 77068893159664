import {gql} from "@apollo/client";
import {PAGINATOR_INFO_FRAGMENT, SIMPLE_PAGINATOR_INFO_FRAGMENT} from "../../../elements/graphql/paginatorInfo";
import {PRODUCT_SPECIFICATION_FRAGMENT} from "./specification";
import {graphql, module} from "../../modules";
import {productGraphql} from "../config";


export default function PRODUCT_FRAGMENT() {
    return gql`
        ${graphql("storage.storage").fragment}
        ${graphql("categories.category").fragment}
        fragment ProductFragment on Product {
            id
            active
            sold
            variant_id
            connected_id
            name
            names
            code
            model
            barcode
            brand
            price
            prices
            discount_price
            discount_prices
            process_price
            action
            new
            qty
            badge_1
            badge_2
            description
            descriptions
            seo_title
            seo_keywords
            seo_description
            video
            seo_titles
            seo_keywords_all
            seo_descriptions
            discount_price_start
            discount_price_end
            ${graphql("categories.category", "categories").body}
            ${graphql("storage.storage", "image").body}
        }
    `;
}

export function CREATE_PRODUCT() {
    return gql`
        ${PRODUCT_FRAGMENT()}
        mutation ($name: String!)  {
            createProduct(name: $name) {
                ...ProductFragment
            }
        }
    `
}

export const GET_PRODUCT_QUERY = "getProduct";
export function GET_PRODUCT({supplierProducts, priceDiff} = {}) {
    return gql`
        ${PRODUCT_FRAGMENT()}
        ${productGraphql.specifications ? PRODUCT_SPECIFICATION_FRAGMENT : ""}
        ${graphql("filters.filter").fragment}
        ${(supplierProducts && module("suppliers")) ? graphql("suppliers.supplierProduct").fragment : ""}
        query($id: ID!) {
            getProduct(id: $id) {
                ...ProductFragment
                ${productGraphql.specifications ? `specifications {
                    ...ProductSpecificationFragment
                    children {
                        ...ProductSpecificationFragment
                    }
                }` : ""}
                ${productGraphql.connected ? `connected {
                    ...ProductFragment
                }` : ""}
                ${productGraphql.variants ? `variants {
                    ...ProductFragment
                }` : ""}
                ${graphql("storage.storage", "images").body}
                ${graphql("filters.filter", "filters").body}

                ${priceDiff ? `
                    price_diff
                    min_price
                    max_price
                    go_price
                    crawled_price
                    e_id
                    e_link
                    e_name` : ""}
                ${(supplierProducts && module("suppliers")) ? "supplier_id" : ""}
                ${(supplierProducts && module("suppliers")) ? graphql("suppliers.supplierProduct", "supplier_products").body : ""}
            }
        }
    `
}


export const GET_PRODUCTS_QUERY = "getProducts";
export function GET_PRODUCTS({supplierProducts, priceDiff, viewsCount} = {}) {
    return gql`
        ${PRODUCT_FRAGMENT()}
        ${SIMPLE_PAGINATOR_INFO_FRAGMENT}
        ${(supplierProducts && module("suppliers")) ? graphql("suppliers.supplierProduct").fragment : ""}
        query($first: Int, $page: Int, $ids: [ID!], $search: String, $order: Int,
            $category: [ID], $filters: String, $brands: [String], $price: Float, $brandName: String, 
            $pageName: String, $hasViews: Int, $group: ID, $campaign: String, $hasPriceDiff: Boolean,
            $supplier: ID, $state: Boolean, $nostate: Boolean, $active: Boolean, $deactive: Boolean) {
            getProducts(first: $first, page: $page, search: $search, order: $order, ids: $ids, brands: $brands,
                category: $category, filters: $filters, price: $price, brandName: $brandName, pageName: $pageName,
                 group: $group, hasViews: $hasViews, campaign: $campaign, hasPriceDiff: $hasPriceDiff,
                supplier: $supplier, state: $state, nostate: $nostate, active: $active, deactive: $deactive) {
                data {
                    ...ProductFragment
                    ${viewsCount ? "views_count" : ""}

                    ${priceDiff ? `
                    price_diff
                    min_price
                    max_price
                    go_price
                    crawled_price
                    e_id
                    e_link
                    e_name` : ""}
                    ${(supplierProducts && module("suppliers")) ? "supplier_id" : ""}
                    ${(supplierProducts && module("suppliers")) ? graphql("suppliers.supplierProduct", "supplier_products").body : ""}
                }
                paginatorInfo {
                    ...SimplePaginatorInfoFragment
                }
            }
        }
    `
}
export const GET_PRODUCTS_TOTAL_QUERY = "getProductsTotal";
export function GET_PRODUCTS_TOTAL() {
    return gql`
        query($search: String,$category: [ID], $filters: String, $brands: [String], $brandName: String,
            $pageName: String, $hasViews: Int, $group: ID, $campaign: String, $hasPriceDiff: Boolean,
            $supplier: ID, $state: Boolean, $nostate: Boolean, $active: Boolean, $deactive: Boolean) {
            getProductsTotal(search: $search, brands: $brands,
                category: $category, filters: $filters, brandName: $brandName, pageName: $pageName,
                group: $group, hasViews: $hasViews, campaign: $campaign, hasPriceDiff: $hasPriceDiff,
                supplier: $supplier, state: $state, nostate: $nostate, active: $active, deactive: $deactive)
        }
    `
}


export function UPDATE_PRODUCT() {
    return gql`
        ${PRODUCT_FRAGMENT()}
        mutation ($id: ID!, $position: Int, $name: String, $code: String, $barcode: String, $brand: String, $image_id: ID, $active: Boolean,
            $variant_id: String, $connected_id: String, $price: Float, $discount_price: Float, $action: Boolean, $new: Boolean, $video: String,
            $description: String, $seo_title: String, $seo_description: String, $seo_keywords: String,
            $discount_price_start: String, $discount_price_end: String,
            $detach_image: ID, $attach_images: [ID], $order_images: [ID],
            $attach_categories: [ID], $detach_categories: [ID], $attach_filters: [ID], $detach_filters: [ID],
            $attach_specification: Boolean, $detach_specification: ID, 
            $model: String, $badge_1: Boolean, $badge_2: Boolean, $sold: Boolean)  {

            updateProduct(id: $id, position: $position, name: $name, code: $code, brand: $brand, image_id: $image_id,
                active: $active, variant_id: $variant_id, connected_id: $connected_id, price: $price, discount_price: $discount_price,
                action: $action, new: $new, description: $description, video: $video, barcode: $barcode,
                seo_title: $seo_title, seo_description: $seo_description, seo_keywords: $seo_keywords,
                discount_price_start: $discount_price_start, discount_price_end: $discount_price_end,
                detach_image: $detach_image, attach_images: $attach_images, order_images: $order_images,
                attach_categories: $attach_categories, detach_categories: $detach_categories,
                attach_filters: $attach_filters, detach_filters: $detach_filters,
                attach_specification: $attach_specification, detach_specification: $detach_specification,
                model: $model, badge_1: $badge_1, badge_2: $badge_2, sold: $sold) {
                ...ProductFragment
            }
        }
    `
}

export function DELETE_PRODUCT() {
    return gql`
        ${PRODUCT_FRAGMENT()}
        mutation ($id: ID!) {
            deleteProduct(id: $id) {
                ...ProductFragment
            }
        }
    `
}