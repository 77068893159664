import {lazy} from "react";
//import PreloadEditorWrapper from "./wrappers/PreloadEditorWrapper";
const RichTextEditor = lazy(() => import(/* webpackChunkName: "RichTextEditor" */ "./widgets/Editor/RichTextEditor"));

const exports = {
    //wrappers: [PreloadEditorWrapper],
    widgets: {
        Editor: RichTextEditor
    }
}
export default exports;