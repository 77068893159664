import {useRef, useState} from "react";
import WebsocketContext from "../contexts/WebsocketContext";
import {ws} from "../../_app/config";

function WebsocketWrapper({Next, context}) {
    const [socket, setSocket] = useState(null);
    const [toggle, setToggle] = useState(undefined); // default, no action =  undefined
    const hooks = useRef({});
    const queue = useRef([]);

    const init = (user, name) => {
        if(socket)  return false;
        let liveSocket = new WebSocket(ws());
        liveSocket.onopen = function () {
            console.log("Connected")
            liveSocket.send(message(user, name));
            setSocket(liveSocket);

            queue.current.map(({subject, content, to}) => {
                liveSocket.send(message(subject, content, to));
            });
            queue.current = [];
        };
        liveSocket.onclose = function ()    {
            console.log("Disconnected")
            setSocket(null);
            setTimeout(() => {
                init(user, name);
            }, 3000);
        }
        liveSocket.onmessage = onMessage;
    }
    const onMessage = (e) => {
        const js = JSON.parse(e.data);
        try {
            js.content = JSON.parse(js.content);
        } catch (e) {

        }
        console.log("ONMESSAGE", js)
        if(hooks.current[js.subject])   {
            Object.values(hooks.current[js.subject]).map(callback => {
                if(callback)   callback(js)
            });
        } else {
            console.log(`%cERROR MISSING: no socket hook ${js.subject}`, 'color: orange');
        }
    }
    if(socket)  {
        socket.onmessage = onMessage;   // REattaching onmessage hook da bi imao pristup state-u (messages)
    }

    const message = (subject, content, to) => {
        return JSON.stringify({subject: subject, content: content, to: to || ""});
    }

    const send = (subject, content, to) => {
        if(!socket) {
            queue.current.push({subject, content, to});
            return false;
        }
        const mess = message(subject, content, to)
        console.log("SEND", mess)
        socket.send(mess);
    }

    const hook = (name, file, callback) => {
        if(!hooks.current[name])    {
            hooks.current[name] = {[file]: callback};
            return false;
        }
        hooks.current[name] = {...hooks.current[name], [file]: callback};
    }

    return <WebsocketContext.Provider value={{init, send, hook, toggle, setToggle}}>
        <Next context={context} />
    </WebsocketContext.Provider>
}
export default WebsocketWrapper;

