import {gql} from "@apollo/client";
import {graphql} from "../../modules";
import {loadImage} from "../config";


function SETTING_FRAGMENT() {
    return gql`
        ${loadImage ? graphql("storage.storage").fragment : ""}
        fragment SettingFragment on Setting {
            id
            field
            value
            values
            ${loadImage ? graphql("storage.storage", "image").body : ""}
        }
    `;
}

export const GET_SETTINGS_QUERY = "getSettings";
export function GET_SETTINGS()    {
    return gql`
        ${SETTING_FRAGMENT()}
        query {
            getSettings {
                ...SettingFragment
            }
        }
    `
}

export function UPSERT_SETTING() {
    return gql`
        ${SETTING_FRAGMENT()}
        mutation UpsertSetting($id: ID, $field: String, $value: String, $image_id: ID)  {
            upsertSetting(id: $id, field: $field, value: $value, image_id: $image_id) {
                ...SettingFragment
            }
        }
    `
}

