export function server(path) {
    //return `https://new.gogorila.com/${path}`;
    if(path.startsWith("/")) path = path.substring(1);
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return `http://localhost:5000/${path}`;
    }
    return `https://new.gogorila.com/${path}`;
}
export function graphQL() {
    return server("graphql");
}
export function ws() {
    return "wss://new.gogorila.com:8001/ws"
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return "ws://localhost:8000/ws";
    }
    return "wss://new.gogorila.com:8001/ws"
}

export function services(path) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return `http://localhost:6543/${path}`;
    }
    return `https://services.gogorila.com/${path}`
}

