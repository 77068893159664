import React, {useContext} from "react";
import {Heart} from "react-feather";
import AppContext from "../../_app/AppContext";


function WishlistProductButton({id, size= 24, title = null}) {
    const {toggle, wishlist} = useContext(AppContext);
    const fill = wishlist.includes(parseInt(id))
        ? {fill: "#d2520b", stroke: "#d2520b"}
        : {stroke: "gray"};

    return (
        <button onClick={() => toggle(id)}>
            <Heart {...fill} size={size} /> {title}
        </button>
    )
}
export default WishlistProductButton;