import {lazy} from "react";
import {SUPPLIER_FRAGMENT} from "./graphql/suppliers";
import {SUPPLIER_PRODUCT_FRAGMENT} from "./graphql/supplierProduct";
const CmsCategoriesMapped = lazy(() => import("./widgets/CmsCategoriesMapped"));
const CmsSupplierDrawer = lazy(() => import("./widgets/CmsSupplierDrawer"));
const CmsUpdateReports = lazy(() => import("./views/cmsUpdateReports/CmsUpdateReports"));
const CmsSuppliers = lazy(() => import("./views/cmsSuppliers/CmsSuppliers"));
const CmsProductSuppliers = lazy(() => import("./widgets/CmsProductSuppliers"));
const CmsStats = lazy(() => import("./views/cmsStats/CmsStats"));


const exports = {
    routes: [
        {
            name: "cmsSuppliers",
            trans: ["Dobavljači", "Suppliers"],
            path: ["dobavljaci", "Suppliers"],
            element: <CmsSuppliers />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 30}},
        },
        {
            name: "cmsPopularByCategories",
            trans: ["Statistika (dobavljači)", "Statistics (suppliers)"],
            path: ["dobavljaci-statistika-kategorije", "Suppliers-statistics-categories"],
            parent: "products.cmsProductsTab",
            element: <CmsStats />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 2}},
        },
        {
            name: "cmsUpdates",
            parent: "cms.cmsDashboardTab",
            path: ["azuriranje", "updates"],
            trans: ["Ažuriranje", "Updates"],
            element: <CmsUpdateReports />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 2}},
        },
    ],
    preloads: [],
    graphql: {
        supplier: SUPPLIER_FRAGMENT,
        supplierProduct: SUPPLIER_PRODUCT_FRAGMENT
    },
    widgets: {
        CmsProductSuppliers: CmsProductSuppliers,
        CmsSupplierDrawer: CmsSupplierDrawer,
        CmsCategoriesMapped: CmsCategoriesMapped
    }
}
export default exports;
