import {useQuery} from "@apollo/client";
import {GET_SETTINGS_QUERY, GET_SETTINGS} from "../graphql/setting";


function LoadSettingsWrapper({Next, context}) {
    const {data, loading} = useQuery(GET_SETTINGS(), {
        skip: window.goSettings
    });
    if(loading) {
        return null;
    }

    let settingsData = [];
    if(window.goSettings)   {
        settingsData = window.goSettings;
    } else {
        if(data && data[GET_SETTINGS_QUERY]) {
            settingsData = data[GET_SETTINGS_QUERY];
        }
    }
    let settings = {};
    for(let i=0; i<settingsData.length; i++)    {
        const item = settingsData[i];
        settings[item.field] = item;
    }

    const setting = (name, full=false) => {
        if(!settings) return "";
        if(!name) return "";
        if(full)    {
            return settings[name] || {};
        }
        if(!settings[name]?.values[context.lang]) {
            return settings[name]?.values[0];
        }
        return settings[name]?.values[context.lang] || "";
    }

    return <Next context={{...context, settings, setting}} />
}
export default LoadSettingsWrapper;