import {gql} from "@apollo/client";
import {RESPONSE_FRAGMENT} from "../../../elements/graphql/response";


export const ADMIN_FRAGMENT = gql`
    fragment AdminFragment on Admin {
        id 
        name
        lastname
        username
        email
        count_login_attempts
        last_login
    }
`;

export const GET_SELF_QUERY = "getAdmin";
export const GET_SELF = gql`
    ${ADMIN_FRAGMENT}
    query {
        getAdmin {
            ...AdminFragment
        }
    }
`

export const GET_ADMIN_BY_ID_QUERY = "getAdminById";
export const GET_ADMIN_BY_ID = gql`
    ${ADMIN_FRAGMENT}
    query ($id: ID!) {
        getAdminById(id: $id) {
            ...AdminFragment
        }
    }
`

export const GET_ADMINS_QUERY = "getAdmins";
export const GET_ADMINS = gql`
    ${ADMIN_FRAGMENT}
    query {
        getAdmins {
            ...AdminFragment
        }
    }
`

export const CREATE_ADMIN = gql`
    ${ADMIN_FRAGMENT}
    mutation ($name: String!, $lastname: String!, $email: String!, $password: String!)  {
        createAdmin(name: $name, lastname: $lastname, email: $email, password: $password) {
            ...AdminFragment
        }
    }
`
export const DELETE_ADMIN = gql`
    ${ADMIN_FRAGMENT}
    mutation ($id: ID!) {
        deleteAdmin(id: $id) {
            ...AdminFragment
        }
    }
`
export const UPDATE_ADMIN = gql`
    ${ADMIN_FRAGMENT}
    mutation ($id: ID!, $name: String, $lastname: String, $username: String) {
        updateAdmin(id: $id, name: $name, lastname: $lastname, username: $username) {
            ...AdminFragment
        }
    }
`


export const LOGIN_ADMIN = gql`
    ${RESPONSE_FRAGMENT}
    mutation ($email: String!, $password: String!)  {
        loginAdmin(email: $email, password: $password) {
            ...ResponseFragment
        }
    }
`


