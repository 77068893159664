import {Toaster} from "react-hot-toast";
import Router from "./Router";
import {ApolloProvider} from "@apollo/client";
import {apolloClient} from "./apolloClient";
import {randString} from "../../elements/helpers";
import {modulesWrappers, widget} from "../modules";


function App() {

    const checkIsMobile = () => {
        let width;
        if(window.outerHeight)   width = window.outerWidth;
        else                     width = document.body.clientWidth;
        return width < 768;
    }
    const checkClientCode = () => {
        let code = localStorage.getItem("client");
        if(!code)   {
            const abcd = ['A', 'D', 'f', 'T', 'q', 'M', 'c', 'S', 'o', 'P'];
            const unique = Date.now().toString();
            code = randString(4);
            for (var i = 0; i < unique.length; i++) {
                code = code + abcd[unique.charAt(i)];
            }
            localStorage.setItem("client", code);
        }
        return code;
    }

    const context = {
        session: randString(6),
        isMobile: checkIsMobile(),
        client: checkClientCode(),
        admin: widget("cms.providerCheckAdmin", null),
        user: widget("users.providerCheckUser", null),
        languages: widget("lang.languages"),
        lang: 0
    };
    const client = apolloClient(context);

    const routerWrappers = [...modulesWrappers, Router];
    routerWrappers.map((item, i) => item.defaultProps = {Next: routerWrappers[i+1] || null});
    const First = routerWrappers[0];

    return (
        <ApolloProvider client={client}>
            <Toaster />

            <First context={context} />
        </ApolloProvider>
    );
}

export default App;
