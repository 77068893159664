import {lazy} from "react";
import slugify from "slugify";
import useQueryParser from "./widgets/useQueryParser";
import PRODUCT_FRAGMENT from "./qraphql/product";
import {brandUrls, groupUrls, productUrls, shopUrls} from "./config";
const ProductRender = lazy(() => import("./widgets/ProductRender"));
const LoadProductsCarousel = lazy(() => import("./widgets/LoadProductsCarousel"));
const ProductsCarousel = lazy(() => import("./widgets/ProductsCarousel"));
const CmsProductDrawer = lazy(() => import("./widgets/CmsProductDrawer"));
const CmsProductsDrawer = lazy(() => import("./widgets/CmsProductsDrawer"));
const CmsProducts = lazy(() => import("./views/cmsProducts/CmsProducts"));
const Shop = lazy(() => import("./views/shop/Shop"));
const ShopDrawer = lazy(() => import("./widgets/ShopDrawer"));


const exports = {
    routes: [
        {
            name: "cmsProductsTab",
            trans: ["Proizvodi", "Products"],
            path: ["proizvodi", "products"],
            element: <CmsProducts />,
            layouts: ["cms.CmsLayout"],
            menus: {cmsLeft: {position: 10}},
        },
        {
            name: "product",
            path: [productUrls[0]+"/:productId", productUrls[1]+"/:productId"],
            element: <Shop />,
            layouts: ["public.PublicLayout"],
            compile: (product, lang) => {
                if(!product) return "shop";
                let slug = "/" + productUrls[lang];
                if(product.category?.breadcrumbs)   {
                    const breadcrumbs = product.category.breadcrumbs.map(it => it.slug);
                    slug += "/" + breadcrumbs.join("/");
                }
                slug = slug + "/" + product.id + "/" + slugify(product.name);
                return slug;
            }
        },
        {name: "product1", path: [productUrls[0]+"/:productId/:slug", productUrls[1]+"/:productId/:slug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "product2", path: [productUrls[0]+"/:categorySlug/:productId/:slug", productUrls[1]+"/:categorySlug/:productId/:slug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "product3", path: [productUrls[0]+"/:param1/:categorySlug/:productId/:slug", productUrls[1]+"/:param1/:categorySlug/:productId/:slug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "product4", path: [productUrls[0]+"/:param1/:param2/:categorySlug/:productId/:slug", productUrls[1]+"/:param1/:param2/:categorySlug/:productId/:slug"], element: <Shop />, layouts: ["public.PublicLayout"]},

        {
            name: "category",
            path: shopUrls,
            element: <Shop />,
            layouts: ["public.PublicLayout"],
            compile: (category, lang) => {
                let slug = "/" + shopUrls[lang];
                if(!category) return slug;
                if(!category.breadcrumbs)   return slug + "/" + category.slug;
                const breadcrumbs = category.breadcrumbs.map(it => it.slug);
                return slug + "/" + breadcrumbs.join("/");
            }
        },
        {name: "category1", path: [shopUrls[0]+"/:categorySlug", shopUrls[1]+"/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "category2", path: [shopUrls[0]+"/:param1/:categorySlug", shopUrls[1]+"/:param1/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "category3", path: [shopUrls[0]+"/:param1/:param2/:categorySlug", shopUrls[1]+"/:param1/:param2/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},

        {name: "action", path: ["akcija", "action"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "action1", path: ["akcija/:categorySlug", "action/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "action2", path: ["akcija/:param1/:categorySlug", "action/:param1/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "action3", path: ["akcija/:param1/:param2/:categorySlug", "action/:param1/:param2/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},

        {name: "new", path: ["novo", "new"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "new1", path: ["novo/:categorySlug", "new/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "new2", path: ["novo/:param1/:categorySlug", "new/:param1/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "new3", path: ["novo/:param1/:param2/:categorySlug", "new/:param1/:param2/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},

        {
            name: "brand",
            path: brandUrls,
            element: <Shop />,
            layouts: ["public.PublicLayout"],
            compile: (brandName, lang) => {
                let slug = "/" + brandUrls[lang];
                if(!brandName) return slug;
                return slug + "/" + encodeURIComponent(brandName.toLowerCase());
            }
        },
        {name: "brand1", path: [brandUrls[0]+"/:brandName", brandUrls[1]+"/:brandName"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "brand2", path: [brandUrls[0]+"/:brandName/:categorySlug", brandUrls[1]+"/:brandName/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "brand3", path: [brandUrls[0]+"/:brandName/:param2/:categorySlug", brandUrls[1]+"/:brandName/:param2/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "brand4", path: [brandUrls[0]+"/:brandName/:param2/:param3/:categorySlug", brandUrls[1]+"/:brandName/:param2/:param3/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},

        {
            name: "group",
            path: ["grupa", "group"],
            element: <Shop />,
            layouts: ["public.PublicLayout"],
            compile: (slug, lang) => {
                if(!slug) return "shop";
                return "/" + groupUrls[lang] + "/" + slug;
            }
        },
        {name: "group1", path: [groupUrls[0]+"/:groupSlug", groupUrls[1]+"/:groupSlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "group2", path: [groupUrls[0]+"/:groupSlug/:categorySlug", groupUrls[1]+"/:groupSlug/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "group3", path: [groupUrls[0]+"/:groupSlug/:param2/:categorySlug", groupUrls[1]+"/:groupSlug/:param2/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},
        {name: "group4", path: [groupUrls[0]+"/:groupSlug/:param2/:param3/:categorySlug", groupUrls[1]+"/:groupSlug/:param2/:param3/:categorySlug"], element: <Shop />, layouts: ["public.PublicLayout"]},

    ],
    graphql: {
        product: PRODUCT_FRAGMENT
    },
    widgets: {
        CmsProductDrawer: CmsProductDrawer,
        CmsProductsDrawer: CmsProductsDrawer,
        ProductRender: ProductRender,
        ProductsCarousel: ProductsCarousel,
        LoadProductsCarousel: LoadProductsCarousel,
        ShopDrawer: ShopDrawer,
        useQueryParser: useQueryParser
    },
}
export default exports;